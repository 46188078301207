export default {
  // customer_summary: "récapitulatif client",
  // cus_name: "Name",
  // display_name: "Nom",
  // due_inv_count: "#Dû",
  // due_inv_amount: "Dû la somme",
  // overdue_inv_count: "#Overdue",
  // overdue_inv_amount: "Overdue Amt",
  // total_inv_count: "#Total",
  // total_inv_amount: "Total Amt",
  // last_action_date: "Last Action Date",
  // last_action_name: "Last Action Name",
  // last_action_comment: "Last Action Comment",
  // next_action_date: "Next Action Date",
  // sugg_next_action: "Suggested Next Action",
  // workflow: "Workflow",
  // rel_manager: "Relation Manager",
  // max_payment_delay: "Max Payment Delay",
  // avg_payment_delay: "Avg Payment Delay",
  // last_response_date: "Last Response Date",
  // days_after_last_response: "Days After Last Response",
  // save_as_new_view: "Save as New View",
  // create: "Create",
  // default_view: "Default View",
  // enable_for_organization_users: "Enable for organization users",
};
