import { async } from "q";
import { axiosWithAuth } from "../providers/AuthProvider";
import { download } from "../utils/downloadFile";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const HOST = process.env.REACT_APP_HOST_AUTH;

export const getLayout = async () => {
  return await axiosWithAuth.get(`${HOST}customer-portal/layout`);
};

export const welcomeTextLayout = async () => {
  return await axiosWithAuth.get(
    `${HOST}customer-portal/portal-page/WELCOME_TEXT`
  );
};

export const getInvoice = async ({ is_internal_call, sort, ...filters }) => {
  return await axiosWithAuth.get(`${HOST}customer-portal/invoice`, {
    params: {
      sort,
      ...filters,
      ...(is_internal_call !== "null" ? { is_internal_call } : {}),
    },
  });
};

export const getCreditNotes = async ({ sort, ...filters }) => {
  return await axiosWithAuth.get(`${HOST}customer-portal/credit-notes`, {
    params: {
      sort,
      ...filters,
    },
  });
};

export const getAuthDtl = async ({ domain = null }) => {
  return await axiosWithAuth.get(`${HOST}customer-portal/organization`, {
    params: {
      domain,
    },
  });
};

export const getPayment = async ({ is_internal_call }) => {
  return await axiosWithAuth.get(`${HOST}customer-portal/payment-detail`, {
    params: {
      ...(is_internal_call !== "null" ? { is_internal_call } : {}),
    },
  });
};

// export const getPaymentList = async () => {
//   return await axiosWithAuth.get(`${HOST}customer-portal/payment-list/`);
// };
export const getPaymentList = async ({ currency }) => {
  return await axiosWithAuth.get(
    `${HOST}customer-portal/payment-list?currency=${currency}`
  );
};
export const paymentVerify = async ({ paymentId }) => {
  return await axiosWithAuth.get(
    `${HOST}customer-portal/verify-payment/${paymentId}`
  );
};

export const getPromiseToPay = async ({ ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/promise-to-pay`,
    data
  );
};

export const getHomePage = async ({ queryParam, ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/organization/token`,
    data,
    {
      params: {
        authType: queryParam,
      },
    }
  );
};

export const getConfigField = async ({ token, ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/organization/link`,
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const getCallback = async ({ ...data }) => {
  return await axiosWithAuth.post(`${HOST}customer-portal/callback`, data);
};

export const postTransferMade = async ({ ...data }) => {
  return await axiosWithAuth.post(`${HOST}customer-portal/payment-paid`, data);
};

export const postOnlineData = async ({ ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/payment_session`,
    data
  );
};

export const invoiceDownload = async ({ invoiceId }) => {
  return await axiosWithAuth
    .get(`${HOST}customer-portal/invoice/${invoiceId}/invoice-download `, {
      responseType: "arraybuffer",
    })
    .then((blob) => {
      download({
        fileName: "invoice.pdf",
        fileData: blob.data,
        fileOptions: {
          type: "application/pdf",
        },
      });
    });
};
export const paymentreceipt = async ({ invoiceId }) => {
  return await axiosWithAuth
    .get(
      `${HOST}customer-portal/invoice/${invoiceId}/payment-receipt-download `,
      {
        responseType: "arraybuffer",
      }
    )
    .then((blob) => {
      download({
        fileName: "invoice.pdf",
        fileData: blob.data,
        fileOptions: {
          type: "application/pdf",
        },
      });
    });
};

export const getCallBackLater = async ({ entity }) => {
  return await axiosWithAuth.get(
    `${HOST}customer-portal/portal-page/${entity}`
  );
};
export const uploaddocument = async ({ data }) => {
  return await axiosWithAuth.put(
    `${HOST}customer-portal/payment-paid/${data.id}`,
    data?.updatedata
  );
};
export const paymentDocument = async ({ data }) => {
  return await axiosWithAuth.put(
    `${HOST}customer-portal/payment-paid/${data?.id}`,
    data?.updatedata
  );
};
export const paymentPlanned = async (data) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/payment-plan/manual`,
    data
  );
};
export const popUp = async (data) => {
  return await axiosWithAuth.get(`${HOST}customer-portal/portal-page/POP_UP`);
};

export const postPrePaymentPlan = async (data) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/payment-plan/pre-defined`,
    data
  );
};

export const checkAuthorizsed = async ({ ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/document-authorized-token`,
    data
  );
};

export const debtorRightConfirmation = async () => {
  return await axiosWithAuth.patch(`${HOST}customer-portal/debtor-rights`);
};

export const createAutoDebitPaymentPlan = async (data) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/payment-plan/online`,
    data
  );
};

export const emailSubceribe = async ({ data }) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/change-subscribe-status`,
    { ...data }
  );
};

export const paymentPlanComputeFn = async (data) => {
  return await axiosWithAuth.post(
    `${HOST}customer-portal/payment-plan-discount`,
    data
  );
};

export const getEsignTemplate = async ({ signId, headers, ...data }) => {
  return await axios.post(
    `${HOST}customer-portal/e-sign/${signId}`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: headers,
      },
    }
  );
};

export const sendTemplateContent = async ({ signId, headers, data }) => {
  return await axios.put(`${HOST}customer-portal/e-sign/${signId}`, data, {
    headers: {
      Authorization: headers,
    },
  });
};

export const authChat = async (data) => {
  let { ...rest } = data;
  return await axiosWithAuth.post(
    `${HOST}customer-portal/chat`,
    {
      ...rest,
    },
    {
      params: {
        reqType: "CP_DOCUMENT",
      },
    }
  );
};

export const chatWithUs = async (data) => {
  let { session_id, ...rest } = data;
  return await axiosWithAuth.put(
    `${HOST}plugin/chat/${session_id}`,
    {
      ...rest,
    }
    // {
    //   headers: {
    //     Authorization: token,
    //   },
    // }
  );
};
